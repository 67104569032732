<template lang="pug">
  v-container(md)
    v-row(justify="center")
      v-col(cols="10")
        router-view(:changeReport="changeReport")
      v-col(cols="10")
        ResultList
</template>

<script>
import ResultList from '@/views/reports/components/ResultList';

export default {
  components: {
    ResultList,
  },
  data() {
    return {};
  },
  mounted() {
    this.$store.dispatch(`REPORTS/fetchReports`);
  },
  methods: {
    changeReport(id) {
      if (id) this.$router.push({ name: 'reports:card', params: { id } });
      else this.$router.push({ name: 'reports' });
    },
  },
};
</script>
