<template lang="pug">
v-card
  v-toolbar(flat)
    v-toolbar-title Готовые отчеты
    v-spacer
    v-btn(
      text
      :disabled="results.length == 0"
      @click="clearResults"
    ) Очистить
  v-divider
  v-card-text.text-center.pa-10(v-if="results.length == 0" ) Здесь пока пусто. Сформируйте свой первый отчет.
  ResultItem(
    v-for="item in results"
    :key="item.id"
    :item="item"
    :report="getReportById(item.reportId)"
    :expanded="isExpandedResult(item.id)"
    @toggle="() => handleToggle(item.id)"
  )
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  components: {
    ResultItem: () => import('./ResultItem'),
  },

  computed: {
    ...mapGetters('REPORTS', [
      'availableReports',
      'results',
      'isExpandedResult',
    ]),
  },

  methods: {
    ...mapActions('REPORTS', [
      'expandResult',
      'unexpandResult',
      'clearResults',
    ]),

    getReportById(id) {
      return this.availableReports.find(item => item.id === id);
    },
    handleToggle(id) {
      if (this.isExpandedResult(id)) {
        this.unexpandResult(id);
      } else {
        this.expandResult(id);
      }
    },
  },
};
</script>
